import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Video } from "../../classes/Video";
import { VideoFormQuery } from "../../fragments/NodeVideoFormFields";
// import { colors } from "./colors";
// import { Typography } from "./Typography";
import HubspotForm from "react-hubspot-form";
import styled from "styled-components";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";
import ReactMarkdown from "react-markdown";
// import { HubUserContext, isValidUser } from "../Context/HubspotContext";
// import { buttonStyles } from "./Buttons/WmkButton";
// import { HubUserContext, isValidUser } from "../context/HubspotContext";

const VideoFormContainer = styled(Container)<{ width: number }>`
  .copy-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20%;
    text-align: center;
    color: ${colors.bodyText.getHex()};
    margin-bottom: 4rem;
    padding: 0 25%;
    font-family: arvo;
    font-size: 15px;
  }

  .main-row {
    margin-bottom: 10rem;
  }

  .hs-form-field {
    label {
      color: ${colors.bodyText.getHex()};
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
    }
    .hs-input {
      width: 100%;
      border: 3px solid #e4effa;
    }
  }
  .form-columns-1 {
    .hs-input {
      width: 100% !important;
    }
  }
  .form-col > div {
    ${({ width }) =>
      width > 991
        ? `&:nth-child(1) {
          height: 100%;
        }`
        : `&:nth-child(1) {
          height: auto;
        }`};

    .row {
      height: fit-content;
    }
    div {
      ${({ width }) =>
        width > 991
          ? `
          height: 100%;`
          : ``};
      form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .hs-submit {
        height: fit-content;
      }
    }
    .input {
      height: fit-content;
    }
  }
  .hs-form {
    position: relative;
    display: flex;
    align-items: center;
    fieldset {
      width: 100%;
      max-width: 100%;
    }
  }
  .hs-submit {
    margin-top: 2rem;
    input {
      padding: 0.5rem 4rem;
      width: fit-content;
      display: flex;
      border: 2px solid ${colors.yellow.getHex()};
      background: ${colors.yellow.getHex()};
      text-transform: uppercase;
      transform: scale(1);
      transition: all 0.3s ease;
      text-decoration: none;
      color: ${colors.charcoal.getHex()};

      &:hover {
        transform: scale(1.1);
        transition: all 0.3s ease;
        background: white;
        color: ${colors.charcoal.getHex()};
        border: 2px solid ${colors.yellow.getHex()};
      }
      &:active,
      &:focus {
        border: 2px solid ${colors.yellow.getHex()};
        background: none;
        transition: all 0.3s ease;
      }
    }
    height: fit-content;
    ${({ width }) =>
      width > 991
        ? `position: absolute;
        bottom: -25%;
        left: -6.5rem;`
        : `display: flex;
        justify-content: center`};
  }
  form {
    ${({ width }) =>
      width <= 991 && width > 554
        ? `height: 22rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 10vw;`
        : width <= 554
        ? `height: 22rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 5vw`
        : ``}
  }
  .form-col {
    height: 100%;
    ${({ width }) =>
      width > 991
        ? `& > div:nth-child(1) {
          height: fit-content;
        }`
        : `& > div:nth-child(2) {
          height: 100%;
        }`};
  }
  @media screen and (max-width: 400px) {
    .form-columns-2 {
      .hs-input {
        width: 100% !important;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .copy-col {
      padding: 0 10%;
      margin-bottom: 0;
    }
    .main-row {
      margin-bottom: 5rem;
    }
  }
`;

const VideoForm = ({ videoForm }: { videoForm: VideoFormQuery }) => {
  const [viewWidth, setViewWidth] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const videoRef = useRef<HTMLDivElement>(null);

  const [formUnlocked, setFormUnlocked] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setFormUnlocked(true);
    }
  }, []);

  useEffect(() => {
    if (formUnlocked) {
      setRedirect(true);
    }
  }, [formUnlocked]);

  useEffect(() => {
    if (redirect) {
      setTimeout(() => {
        window.location.href = `/pillars`;
      }, 1000);
    }
  }, [redirect]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    const current = videoRef.current;
    const winWidth = window.innerWidth;
    const rect = current ? current.getBoundingClientRect() : null;
    if (rect) {
      setVideoWidth(rect.width);
    }
    setViewWidth(winWidth);
  };

  useEffect(() => {
    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  }, []);

  const handler = (event: MessageEvent) => {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmit"
    ) {
      if (event.data.id === videoForm.formId) {
        localStorage.setItem("hasCompletedForm", "true");
        setTimeout(() => {
          setFormUnlocked(true);
        }, 2000);
      }
    }
  };
  const video = new Video(videoForm.video);

  const headerDisplay = (
    <Row>
      <Col id="form-header">
        {videoForm.subtitle && (
          <Typography.BLOCKQUOTE
            style={{
              textAlign: "center",
              fontWeight: 700,
              textTransform: "uppercase",
              color: colors.orange.getHex(),
              fontSize: "14px"
            }}>
            {videoForm.subtitle}
          </Typography.BLOCKQUOTE>
        )}
        <Typography.H2
          style={{
            // fontFamily: "Montserrat",
            textAlign: "center",
            fontWeight: 400,
            margin: "0 0 3rem"
          }}>
          {videoForm.title}
        </Typography.H2>
      </Col>
    </Row>
  );

  const largeDisplay = viewWidth > 991;

  return !formUnlocked ? (
    <VideoFormContainer
      fluid={viewWidth <= 991 ? true : false}
      width={viewWidth}>
      <>
        {videoForm?.upperCopy?.upperCopy && (
          <Row>
            <Col className="copy-col">
              <ReactMarkdown>{videoForm.upperCopy.upperCopy}</ReactMarkdown>
            </Col>
          </Row>
        )}
        {largeDisplay && headerDisplay}
        <Row className="main-row">
          <Col
            md={12}
            lg={6}
            style={
              !largeDisplay
                ? { margin: "4rem 0", paddingLeft: 0, paddingRight: 0 }
                : undefined
            }
            ref={!largeDisplay ? videoRef : undefined}>
            <ReactPlayer
              url={video.videoLink ? video.videoLink : video.video}
              width={"100%"}
              height={!largeDisplay ? videoWidth * 0.5625 : "100%"}
              controls
            />
          </Col>
          <Col
            md={12}
            lg={6}
            className={!largeDisplay ? "form-col" : "form-col"}>
            {!largeDisplay && headerDisplay}
            <HubspotForm portalId="21124615" formId={videoForm.formId} />
          </Col>
        </Row>
      </>
    </VideoFormContainer>
  ) : (
    <></>
  );
};

export default VideoForm;
