import { IconDescriptorQuery } from "../fragments/NodeIconBlockFields";
import { IconSnippet } from "./IconSnippet";

export class IconBlock {
  title: string;
  iconSnippets: IconSnippet[];
  content: { content: string };
  constructor(node: IconDescriptorQuery) {
    const _node = { ...node };
    const snippets = _node.iconSnippets.map((snippet) => {
      return new IconSnippet(snippet);
    });
    this.title = _node.title;
    this.iconSnippets = snippets;
    this.content = _node.content;
  }
}
