import * as React from "react";
import { graphql } from "gatsby";
import { SiteMetaDataFields } from "../fragments/NodeSiteMetadataFields";
import { WmkSeo } from "wmk-seo";
import VideoForm from "../components/Blocks/VideoForm";
import { VideoFormQuery } from "../fragments/NodeVideoFormFields";
import { IconBlock } from "../components/Blocks/IconBlock";
import { IconDescriptorQuery } from "../fragments/NodeIconBlockFields";
import Gallery from "../components/Blocks/Gallery";
import { VideoCat } from "../classes/VideoCat";
import { VideoCategoryQuery } from "../fragments/NodeVideoCategoryFields";
import { PillarQuery } from "../fragments/NodePillarFields";
import { Pillar } from "../classes/Pillar";
import PdfCta from "../components/Blocks/PdfCta";

const IndexPage = ({ data }: { data: IndexPageQuery }) => {
  const videoCats = data.gallery.pillars.map((course) => {
    if ("videos" in course) {
      return new VideoCat(course);
    } else {
      return new Pillar(course);
    }
  });
  return (
    <>
      <WmkSeo.Meta
        title={"Home"}
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        slug="/"
        ogImageUrl=""
        twitterImageUrl=""
        twitterHandle=""
      />
      <VideoForm videoForm={data.videoForm} />
      <IconBlock iconBlockData={data.iconBlock} />
      <Gallery
        data={videoCats}
        title={"Popular Courses"}
        homePage
        largeDisplay
      />
      <PdfCta />
    </>
  );
};

export default IndexPage;

interface IndexPageQuery {
  site: SiteMetaDataFields;
  videoForm: VideoFormQuery;
  iconBlock: IconDescriptorQuery;
  gallery: {
    formId?: string;
    pillars: VideoCategoryQuery[] | PillarQuery[];
  };
}

export const query = graphql`
  {
    site {
      ...NodeSiteMetadata
    }
    videoForm: contentfulVideoForm(contentfulid: { eq: "Home Page Form" }) {
      ...NodeVideoFormFields
    }
    iconBlock: contentfulIconDescriptor(
      contentfulid: { eq: "Home Icon Descriptor" }
    ) {
      ...NodeIconDescriptorFields
    }
    gallery: contentfulGallery(contentfulid: { eq: "Popular Courses" }) {
      pillars {
        ...NodePillarFields
      }
    }
  }
`;
