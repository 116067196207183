import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { WmkImage } from "wmk-image";
import { IconBlock as IconBlockClass } from "../../classes/IconBlock";
import { IconDescriptorQuery } from "../../fragments/NodeIconBlockFields";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";

const IconBlockContainer = styled(Container)`
  background: ${colors.lightBlue.getHex()};
  color: white;
  padding: 5rem 0 5rem;
  color: ${colors.bodyText.getHex()};
  margin-bottom: 4rem;
  .title-col {
    margin: 0 0 2rem;
    color: ${colors.primary.getHex()};
  }
  .text-col {
    margin: 0 0 3rem;
    padding: 0 20vw;
    text-align: center;
  }
  .icon-row {
    display: flex;
    justify-content: center;
  }
  .row {
    width: 100%;
  }
  @media screen and (max-width: 545px) {
    .text-col {
      padding: 0 10vw;
    }
  }
`;

const IconCol = styled(Col)`
  text-align: center;
  div {
    margin: 1rem;
  }
`;

export const IconBlock = ({
  iconBlockData
}: {
  iconBlockData: IconDescriptorQuery;
}) => {
  const iconBlock = new IconBlockClass(iconBlockData);
  const { title, iconSnippets, content } = iconBlock;
  return (
    <IconBlockContainer fluid>
      <Row>
        <Col className="title-col">
          <Typography.H2 style={{ textAlign: "center",
          //  fontFamily: "Montserrat" 
           }}>{title}</Typography.H2>
        </Col>
      </Row>
      <Row>
        <Col className="text-col">
          <Typography.P>{content?.content}</Typography.P>
        </Col>
      </Row>
      <Row className="icon-row">
        {Array.isArray(iconSnippets) &&
          iconSnippets.map((snippet, i) => {
            return (
              <IconCol key={`snippet ${i}`} lg={2} md={3}>
                <WmkImage image={snippet.icon} />
                <Typography.P>{snippet.descriptor}</Typography.P>
              </IconCol>
            );
          })}
      </Row>
    </IconBlockContainer>
  );
};
