import { Img } from "wmk-image";
import { IconSnippetQuery } from "../fragments/NodeIconBlockFields";

export class IconSnippet {
  descriptor: string;
  icon: Img
  constructor(node: IconSnippetQuery) {
    const _node = {...node}
    const image = new Img(_node.icon)
    this.descriptor = _node.descriptor
    this.icon = image
  }
}